@media screen and (min-width: 2200px){
    .index-top-section .index-top-slider-wrap:after{height: 170%;}
}
@media screen and (max-width: 1775px){
    //leading section(page)
    .leading-people{
        .mbox{max-width: 1400px;}
    }

    // portfolio page
    .portfolio-gallery-list{max-width:1280px;
        .portfolio-gallery-list-wrap{margin:0 -10px;}
        .item{width: 630px;height: 310px;margin:0 10px 20px;
            .item-info{padding: 12px;
                .item-title{font-size: 30px;margin-bottom: 15px;}
                .item-text{flex:1;display: flex;flex-direction: column;justify-content: center;}
                .leader{margin-bottom: 15px;}
            }
        }
    }
}
@media screen and (max-width: 1600px){
    // texting page type 1 | left - img,right -text
    .texting-type-1{
        .texting-row{
            .item{min-height:600px;}
        }
    }

    //leading-person-info
    .leading-person-info{
        //slider
        .leading-person-slider {max-width:1160px;}
    }
}
@media screen and (max-width: 1400px){
    //index page top section
    .index-top-section{
        .index-top-slider-wrap{
            &:after{top:65px;}
            .item{height: 800px;}
        }
        .middle{
            .circle{width: 530px;height: 530px;}
            .circle-datepiker{padding-bottom: 30px;
                .circle-datepiker-title{font-size: 20px;}
                p{font-size: 14px;max-width:300px;margin-left: auto;margin-right:auto;}
            }
            .circle-bottom-title{width: 530px;}
        }
    }

    //leading section(page)
    .leading-people{
        .mbox{max-width: 1160px;}
        .leading-people-wrap {
            .item{width: 270px;height: 320px;margin: 0 10px 20px;
                .item-title{font-size: 17px;
                    p{margin:0.9em 0;}
                }
            }
        }
    }

    //index black slider
    .index-black-slider{
        .slider-section{
            .slider-big{max-width:800px;margin:0 auto 10px;width: 100%;
                .item{height: 485px;}
            }
        }
    }

    // leading person
    .leading-person-info {
        .leading-person-youtube{height: 590px;}
    }

    // portfolio page
    .portfolio-gallery-list{max-width: 1160px;
        .item{width: 570px;height: 300px;}
    }

}

@media screen and (min-width: 1280px) and (max-width:1400px){

    //portfolio current event
    .information-about-event{
        $swraperHeight:500px;
        .left-side,.right-side{height: $swraperHeight;width: 50%;}
        .left-side {
            .grey{	padding: 40px 25px 30px 25px;}
            .white{padding: 20px 20px 20px 25px;}
        }
        .right-side{
            .slider-big{
                .item{height: $swraperHeight;}
            }
            .slider-navigation{
                .item{height: $swraperHeight/4 - 14px;}
            }
        }
    }

}

@media screen and (max-width: 1280px){
    //index black slider
    .index-black-slider{
        .slider-section{
            .slider-navigation{max-width: 450px;width: 100%;margin:0 auto;
                .item{height: 90px;}
            }
        }
    }

    // portfolio page
    .portfolio-gallery-list{
        .portfolio-gallery-list-wrap{justify-content: center;}
    }

    //portfolio current event
    .information-about-event{flex-direction: column;

        .left-side,.right-side{height: auto;width: 100%;}
        .left-side {
            .grey{padding: 40px 25px 30px 25px;order:2;
                .event-text{max-height:100%;}
            }
            .white{padding: 20px 20px 20px 25px;order:1;}
        }
    }

    //ctext images
    .ctext-img{margin:0 auto 30px;
        &.three{
            img{height: 400px;}
        }
    }
}
@media screen and (max-width: 1024px){
    //popups
    .feedback-popup{padding: 30px;
        .contact-form{width: 100%;min-height:300px;}
    }

    //header
    .header{
        .header-burger{top:20px;right:20px;}
        .lenguage-mobile{display: block;}
        .header-inner{padding: 14px;height: auto;}
    }

    //index black slider
    .index-black-slider{
        .slider-section{
            .slider-big{
                .item{height: 550px;}
            }
        }
    }

    //leading section(page)
    .leading-people{
        .leading-people-wrap{justify-content: center;}
    }

    // portfolio page
    .portfolio-gallery-list{
        .item .item-info{opacity:1;transform:scale(1);}
    }

    //portfoli current services
    .services-includes{margin-bottom: 30px;
        .services-includes-list{
            .item{width: 48%;margin-bottom: 20px;}
        }
    }

}
@media screen and (max-width: 992px){
    //index page top section
    .index-top-section{
        .index-top-slider-wrap{
            &:after{top:94px;}
            .item{height: 400px;}
        }
        .middle{
            .circle-datepiker{display: none;}
            .circle{width: 240px;height: 240px;padding: 35px;
                &:after{display: none;}
                .circle-logo{
                    img{object-fit:contain;}
                }
            }
            .circle-bottom-title{width: 280px;font-size: 16px;}
        }
    }

    //header
    .header {
        .header-menu {
            .header-menu-wrap{padding-top: 40px;}
            .middle-menu{
                .row{flex-wrap:wrap;justify-content: center;
                    .memu-item{width: 35%;margin:0 0 10px;}
                }
            }
        }
    }

    //index page specialist
    .index-specialist {
        .index-specialist-list{flex-direction: column;align-items: center;padding-top: 30px;
            .big-list{margin-right: 0;margin-bottom: 30px;}
            .list-rows{width: 100%;
                .list-rows-wrap{justify-content: center;}
            }
        }
    }

    // texting page type 1 | left - img,right -text
    .texting-type-1{
        .texting-row{flex-direction: column;
            &.reverse{flex-direction: column;}
            .item{width: 100%;min-height:auto;
                &.img{height: 200px;}
                &.info{	padding: 35px 20px;
                    .title{}
                }
            }
        }
        &.leading-person{
            .texting-row {
                .item.img{height: auto;}
            }
            .info-top{padding-top: 0;
                .button.black{margin:0 auto;}
            }
            .info-bottom{justify-content: center;flex-wrap:wrap;}
            .leading-person-page{margin-top: 15px;margin-bottom: 15px;}
        }
    }

    //index page happy with us
    .index-happy-width-us-items{flex-wrap:wrap;justify-content: center;align-items: flex-start;
        .item{width: 45%;
            .item-link {
                .item-title{font-size: 21px;
                    &:after{display: none;}
                }
                .item-subtitle{display: none;}
            }
        }
    }

    //index how we work
    .index-how-we-work{
        .how-we-work-circle{width: auto;height: auto;display: flex;flex-direction: column;margin-top: 70px;
            &:after{display: none;}
            .item{position: relative;left:auto !important;top:auto !important;right:auto !important;display: flex;align-items: center;justify-content: center;margin-bottom: 20px;
                &.item-1{transform: none;
                    // .item-img{display: none;}
                    a{display: flex;width: 100%;align-items: center;justify-content: center;}
                    .how-we-work-link{margin-left: 15px;margin-right: 0;max-width: 210px;}
                }
                .item-img{height: 70px;width: 70px;padding: 6px;margin-bottom: 0px;
                    .item-img-wrap{padding: 15px;}
                }
                .how-we-work-link{margin-top: 10px;width: 100%;margin-bottom: 10px;}
                .item-description{position: static;margin-left: 20px;width: 100%;max-width: 200px;font-size: 18px;
                    &.left{right:calc(100% + 40px);left:auto;
                        span:after{right:auto;left:0;}
                    }
                    span{
                        &:after{display: none;}
                    }
                }
            }
        }
    }


    //portfolio current event
    .information-about-event{
        .right-side{
            .slider-big{
                .item{height: 470px;}
            }
        }
    }

    //ctext
    .ctext{
        blockquote{padding: 25px 10px 25px 60px;
            &:after{left:0;}
            &:before{left:10px;top: 20px;}
        }
    }
     //ctext images
     .ctext-img{
        &.three{
            img{height: 300px;}
        }
    }
}
@media screen and (max-width: 768px){
     //index black slider
     .index-black-slider{
        .slider-section{
            .slider-big{
                .item{height: 400px;}
            }
        }
    }

    //index organizate form
    .index-organizate-form{background-position: left center;
        .mbox{margin-top: 0;margin-bottom: 0;padding-top: 30px;padding-bottom: 30px;}
        .contact-form{
            .contact-form-row{flex-direction: column;
                &.form-rules{flex-direction: row;}
            }
        }
    }

    //footer
    .footer{
        .footer-top{padding-top: 30px;}
        .footer-bottom{padding: 15px 0px;
            .mbox{display: flex;flex-direction: column;align-items: center;}
            .footer-bottom-link{width: 100%;justify-content: center;}
            .ocial-contacts{width: 100%;}
            .created-by-sheepfish{width: auto;}
        }
        .footer-logo{max-width:120px;width: 100%;margin:0 auto 20px;}
        .footer-links{
            &.contacts{padding: 0px;}
        }
        .ocial-contacts{margin: 15px 0px;}
    }

    //leading-person-info
    .leading-person-info{
        //slider
        .leading-person-slider {max-width:400px;}

        .leading-person-youtube{height: 310px;}
    }

}
@media screen and (max-width: 666px){

    //index page
    .index-section{padding-top: 30px;padding-bottom: 30px;}

    //index page top section
    .index-top-section{
        .index-top-slider-wrap{
            &:after{top:0px;height: 700px;right: -30vw;}
        }
        .middle{padding-right: 0;
            .mbox{justify-content: center;}
            .circle{width: 200px;height: 200px;padding: 30px;}
            .circle-bottom-title{display: none;}
        }

        //slick
        .slick-dots{display: none !important;}
    }

    //header
    .header {
        .logo-text{max-width:200px;}
        .header-menu {
            .middle-menu{
                .row{
                    .memu-item{width: 50%;
                        .item-number{margin-bottom: 3px;font-size: 11px;}
                        .plashka{display: none;}
                        .item-subtitle{display: none;}
                        .item-title {
                            .nav-link-span{height: 35px;padding-top: 4px;font-size: 22px;}
                        }
                    }
                }
            }
        }
    }

    //popup detail
    .feedback-popup.detail{
        .contact-form-row{flex-direction: column;align-items: center;
            &.google-captch-wrap{justify-content:center;
                .contact-form-item{margin: 0 auto 30px;}
                .g-recaptcha>div{margin:0 auto;}
            }
        }
        .contact-form-item{max-width:100%;margin: 0 auto;}
    }

    // texting page type 1 | left - img,right -text
    .texting-type-1{
        &.leading-person{
            .info-bottom{flex-direction: column;padding-bottom: 0;}
            .leading-person-page{margin-left: 0;margin-bottom: 0;margin-top: 20px;}
        }
    }

    //social buttons
    .ocial-contacts{
        &.version-2{
            li:before,li:after,.subtext{display: none;}
        }
    }


    // portfolio page
    .portfolio-gallery-list{
        .portfolio-gallery-list-wrap{justify-content: center;}
    }

    // portfolio page
    .portfolio-gallery-list{
        .item{height: auto;min-height:280px;
            .item-info{
                .item-title{font-size: 26px;margin-bottom: 10px;max-height:100%;}
                .leader .leader-text{
                    .leader-text-title{font-size: 18px;}
                }
            }
        }
    }

    //portfoli current event
    .current-event{
        .texting-type-1 .texting-row .item.info .text a.button{margin:0 auto;}
    }
    .services-includes{
        .services-includes-list{flex-direction: column;margin-top: 40px;
            .item{width: 100%;}
        }
    }


    //portfolio current event
    .information-about-event{
        .right-side{
            .slider-big{width: 100%;margin-bottom: 100px;
                .item{height: 350px;margin-right: 0;}
            }
            .slider-navigation{display: none;}
        }
    }

    //ctext images
    .ctext-img{
        &.three{
            img{height: 220px;
                &:nth-child(3){margin-top: 10px;}
            }
        }
    }

    //current news
    .current-news{
        .current-news-next{padding: 10px 15px;
            .prev-news,.next-news{
                .news-img{height: 30px;width: 30px;}
                .news-text{margin:0 15px;}
            }
        }
    }

    //comments page
    .comments-section {
        .title{margin:30px 0px;}
        .comments-holder{
            .comments-item{margin-bottom: 20px;
                .comment-top{margin: 1em 0 1em;justify-content: space-between;
                    .comment-date{text-align: right;padding-left: 10px;
                        &:before{display: none;}
                    }
                }
                .comments-item{margin-left: 10px;}
            }
        }
        .comments-form{
            .comments-form-tag{
                form{flex-direction: column;}
                .form-comment{width: 100%;padding-right: 0;margin-bottom: 30px;}
                .form-other{width: 100%;
                    .input{max-width: 100%;}
                }
                .button{margin:0 auto;}
            }
        }
    }

}
@media screen and (max-width: 479px){

    //index page specialist
    .index-specialist {
        .index-specialist-list{
            .big-list{width: 300px;height: 370px;margin-bottom: 13px;
                .specialist-info .specialist-info-text{font-size: 21px;}
            }
            .list-rows{
                .list-rows-wrap{}
                .list-item{width: 135px;height: 155px;}
            }
        }
    }

    //index black slider
    .index-black-slider{
        .slider-section{
            .slider-big{
                .item{height: 200px;}
            }
        }
    }

    //index page happy with us
    .index-happy-width-us-items{flex-direction: column;
        .item{width: 100%;}
    }

    //popup detail
    .feedback-popup.detail{max-width: 340px;padding: 15px;}


    //leading-person-info
    .leading-person-info{
        //slider
        .leading-person-slider {max-width:320px;
            .item{height: 210px;margin:0 0px;}
        }

        .leading-person-youtube{height: 190px;}
    }

    //portfoli current event
    .services-includes{
        .services-includes-list{
            .item .item-header .item-title{	margin-bottom: 15px;
                margin-top: 15px;
                &:after{bottom: -7px;}
            }
        }
    }

    //portfolio current event
    .information-about-event{
        .right-side{
            .slider-big{
                .item{height: 300px;}
            }
        }
    }

    //ctext images
    .ctext-img{
        &.three{
            img{height: 150px;}
        }
    }
}
@media screen and (max-width: 380px){
    //portfolio current event
    .information-about-event{
        .right-side{
            .slider-big{
                .item{height: 230px;}
            }
        }
    }

    //ctext images
    .ctext-img{
        &.three{
            img{height: 120px;}
        }
    }
}