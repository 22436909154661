@media screen and (min-width: 2200px) {
  .index-top-section .index-top-slider-wrap:after {
    height: 170%;
  }
}

@media screen and (max-width: 1775px) {
  .leading-people .mbox {
    max-width: 1400px;
  }
  .portfolio-gallery-list {
    max-width: 1280px;
  }
  .portfolio-gallery-list .portfolio-gallery-list-wrap {
    margin: 0 -10px;
  }
  .portfolio-gallery-list .item {
    width: 630px;
    height: 310px;
    margin: 0 10px 20px;
  }
  .portfolio-gallery-list .item .item-info {
    padding: 12px;
  }
  .portfolio-gallery-list .item .item-info .item-title {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .portfolio-gallery-list .item .item-info .item-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .portfolio-gallery-list .item .item-info .leader {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1600px) {
  .texting-type-1 .texting-row .item {
    min-height: 600px;
  }
  .leading-person-info .leading-person-slider {
    max-width: 1160px;
  }
}

@media screen and (max-width: 1400px) {
  .index-top-section .index-top-slider-wrap:after {
    top: 65px;
  }
  .index-top-section .index-top-slider-wrap .item {
    height: 800px;
  }
  .index-top-section .middle .circle {
    width: 530px;
    height: 530px;
  }
  .index-top-section .middle .circle-datepiker {
    padding-bottom: 30px;
  }
  .index-top-section .middle .circle-datepiker .circle-datepiker-title {
    font-size: 20px;
  }
  .index-top-section .middle .circle-datepiker p {
    font-size: 14px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .index-top-section .middle .circle-bottom-title {
    width: 530px;
  }
  .leading-people .mbox {
    max-width: 1160px;
  }
  .leading-people .leading-people-wrap .item {
    width: 270px;
    height: 320px;
    margin: 0 10px 20px;
  }
  .leading-people .leading-people-wrap .item .item-title {
    font-size: 17px;
  }
  .leading-people .leading-people-wrap .item .item-title p {
    margin: 0.9em 0;
  }
  .index-black-slider .slider-section .slider-big {
    max-width: 800px;
    margin: 0 auto 10px;
    width: 100%;
  }
  .index-black-slider .slider-section .slider-big .item {
    height: 485px;
  }
  .leading-person-info .leading-person-youtube {
    height: 590px;
  }
  .portfolio-gallery-list {
    max-width: 1160px;
  }
  .portfolio-gallery-list .item {
    width: 570px;
    height: 300px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1400px) {
  .information-about-event .left-side, .information-about-event .right-side {
    height: 500px;
    width: 50%;
  }
  .information-about-event .left-side .grey {
    padding: 40px 25px 30px 25px;
  }
  .information-about-event .left-side .white {
    padding: 20px 20px 20px 25px;
  }
  .information-about-event .right-side .slider-big .item {
    height: 500px;
  }
  .information-about-event .right-side .slider-navigation .item {
    height: 111px;
  }
}

@media screen and (max-width: 1280px) {
  .index-black-slider .slider-section .slider-navigation {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
  }
  .index-black-slider .slider-section .slider-navigation .item {
    height: 90px;
  }
  .portfolio-gallery-list .portfolio-gallery-list-wrap {
    justify-content: center;
  }
  .information-about-event {
    flex-direction: column;
  }
  .information-about-event .left-side, .information-about-event .right-side {
    height: auto;
    width: 100%;
  }
  .information-about-event .left-side .grey {
    padding: 40px 25px 30px 25px;
    order: 2;
  }
  .information-about-event .left-side .grey .event-text {
    max-height: 100%;
  }
  .information-about-event .left-side .white {
    padding: 20px 20px 20px 25px;
    order: 1;
  }
  .ctext-img {
    margin: 0 auto 30px;
  }
  .ctext-img.three img {
    height: 400px;
  }
}

@media screen and (max-width: 1024px) {
  .feedback-popup {
    padding: 30px;
  }
  .feedback-popup .contact-form {
    width: 100%;
    min-height: 300px;
  }
  .header .header-burger {
    top: 20px;
    right: 20px;
  }
  .header .lenguage-mobile {
    display: block;
  }
  .header .header-inner {
    padding: 14px;
    height: auto;
  }
  .index-black-slider .slider-section .slider-big .item {
    height: 550px;
  }
  .leading-people .leading-people-wrap {
    justify-content: center;
  }
  .portfolio-gallery-list .item .item-info {
    opacity: 1;
    transform: scale(1);
  }
  .services-includes {
    margin-bottom: 30px;
  }
  .services-includes .services-includes-list .item {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 992px) {
  .index-top-section .index-top-slider-wrap:after {
    top: 94px;
  }
  .index-top-section .index-top-slider-wrap .item {
    height: 400px;
  }
  .index-top-section .middle .circle-datepiker {
    display: none;
  }
  .index-top-section .middle .circle {
    width: 240px;
    height: 240px;
    padding: 35px;
  }
  .index-top-section .middle .circle:after {
    display: none;
  }
  .index-top-section .middle .circle .circle-logo img {
    object-fit: contain;
  }
  .index-top-section .middle .circle-bottom-title {
    width: 280px;
    font-size: 16px;
  }
  .header .header-menu .header-menu-wrap {
    padding-top: 40px;
  }
  .header .header-menu .middle-menu .row {
    flex-wrap: wrap;
    justify-content: center;
  }
  .header .header-menu .middle-menu .row .memu-item {
    width: 35%;
    margin: 0 0 10px;
  }
  .index-specialist .index-specialist-list {
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }
  .index-specialist .index-specialist-list .big-list {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .index-specialist .index-specialist-list .list-rows {
    width: 100%;
  }
  .index-specialist .index-specialist-list .list-rows .list-rows-wrap {
    justify-content: center;
  }
  .texting-type-1 .texting-row {
    flex-direction: column;
  }
  .texting-type-1 .texting-row.reverse {
    flex-direction: column;
  }
  .texting-type-1 .texting-row .item {
    width: 100%;
    min-height: auto;
  }
  .texting-type-1 .texting-row .item.img {
    height: 200px;
  }
  .texting-type-1 .texting-row .item.info {
    padding: 35px 20px;
  }
  .texting-type-1.leading-person .texting-row .item.img {
    height: auto;
  }
  .texting-type-1.leading-person .info-top {
    padding-top: 0;
  }
  .texting-type-1.leading-person .info-top .button.black {
    margin: 0 auto;
  }
  .texting-type-1.leading-person .info-bottom {
    justify-content: center;
    flex-wrap: wrap;
  }
  .texting-type-1.leading-person .leading-person-page {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .index-happy-width-us-items {
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  .index-happy-width-us-items .item {
    width: 45%;
  }
  .index-happy-width-us-items .item .item-link .item-title {
    font-size: 21px;
  }
  .index-happy-width-us-items .item .item-link .item-title:after {
    display: none;
  }
  .index-happy-width-us-items .item .item-link .item-subtitle {
    display: none;
  }
  .index-how-we-work .how-we-work-circle {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
  }
  .index-how-we-work .how-we-work-circle:after {
    display: none;
  }
  .index-how-we-work .how-we-work-circle .item {
    position: relative;
    left: auto !important;
    top: auto !important;
    right: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .index-how-we-work .how-we-work-circle .item.item-1 {
    transform: none;
  }
  .index-how-we-work .how-we-work-circle .item.item-1 a {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .index-how-we-work .how-we-work-circle .item.item-1 .how-we-work-link {
    margin-left: 15px;
    margin-right: 0;
    max-width: 210px;
  }
  .index-how-we-work .how-we-work-circle .item .item-img {
    height: 70px;
    width: 70px;
    padding: 6px;
    margin-bottom: 0px;
  }
  .index-how-we-work .how-we-work-circle .item .item-img .item-img-wrap {
    padding: 15px;
  }
  .index-how-we-work .how-we-work-circle .item .how-we-work-link {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 10px;
  }
  .index-how-we-work .how-we-work-circle .item .item-description {
    position: static;
    margin-left: 20px;
    width: 100%;
    max-width: 200px;
    font-size: 18px;
  }
  .index-how-we-work .how-we-work-circle .item .item-description.left {
    right: calc(100% + 40px);
    left: auto;
  }
  .index-how-we-work .how-we-work-circle .item .item-description.left span:after {
    right: auto;
    left: 0;
  }
  .index-how-we-work .how-we-work-circle .item .item-description span:after {
    display: none;
  }
  .information-about-event .right-side .slider-big .item {
    height: 470px;
  }
  .ctext blockquote {
    padding: 25px 10px 25px 60px;
  }
  .ctext blockquote:after {
    left: 0;
  }
  .ctext blockquote:before {
    left: 10px;
    top: 20px;
  }
  .ctext-img.three img {
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  .index-black-slider .slider-section .slider-big .item {
    height: 400px;
  }
  .index-organizate-form {
    background-position: left center;
  }
  .index-organizate-form .mbox {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .index-organizate-form .contact-form .contact-form-row {
    flex-direction: column;
  }
  .index-organizate-form .contact-form .contact-form-row.form-rules {
    flex-direction: row;
  }
  .footer .footer-top {
    padding-top: 30px;
  }
  .footer .footer-bottom {
    padding: 15px 0px;
  }
  .footer .footer-bottom .mbox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer .footer-bottom .footer-bottom-link {
    width: 100%;
    justify-content: center;
  }
  .footer .footer-bottom .ocial-contacts {
    width: 100%;
  }
  .footer .footer-bottom .created-by-sheepfish {
    width: auto;
  }
  .footer .footer-logo {
    max-width: 120px;
    width: 100%;
    margin: 0 auto 20px;
  }
  .footer .footer-links.contacts {
    padding: 0px;
  }
  .footer .ocial-contacts {
    margin: 15px 0px;
  }
  .leading-person-info .leading-person-slider {
    max-width: 400px;
  }
  .leading-person-info .leading-person-youtube {
    height: 310px;
  }
}

@media screen and (max-width: 666px) {
  .index-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .index-top-section .index-top-slider-wrap:after {
    top: 0px;
    height: 700px;
    right: -30vw;
  }
  .index-top-section .middle {
    padding-right: 0;
  }
  .index-top-section .middle .mbox {
    justify-content: center;
  }
  .index-top-section .middle .circle {
    width: 200px;
    height: 200px;
    padding: 30px;
  }
  .index-top-section .middle .circle-bottom-title {
    display: none;
  }
  .index-top-section .slick-dots {
    display: none !important;
  }
  .header .logo-text {
    max-width: 200px;
  }
  .header .header-menu .middle-menu .row .memu-item {
    width: 50%;
  }
  .header .header-menu .middle-menu .row .memu-item .item-number {
    margin-bottom: 3px;
    font-size: 11px;
  }
  .header .header-menu .middle-menu .row .memu-item .plashka {
    display: none;
  }
  .header .header-menu .middle-menu .row .memu-item .item-subtitle {
    display: none;
  }
  .header .header-menu .middle-menu .row .memu-item .item-title .nav-link-span {
    height: 35px;
    padding-top: 4px;
    font-size: 22px;
  }
  .feedback-popup.detail .contact-form-row {
    flex-direction: column;
    align-items: center;
  }
  .feedback-popup.detail .contact-form-row.google-captch-wrap {
    justify-content: center;
  }
  .feedback-popup.detail .contact-form-row.google-captch-wrap .contact-form-item {
    margin: 0 auto 30px;
  }
  .feedback-popup.detail .contact-form-row.google-captch-wrap .g-recaptcha > div {
    margin: 0 auto;
  }
  .feedback-popup.detail .contact-form-item {
    max-width: 100%;
    margin: 0 auto;
  }
  .texting-type-1.leading-person .info-bottom {
    flex-direction: column;
    padding-bottom: 0;
  }
  .texting-type-1.leading-person .leading-person-page {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 20px;
  }
  .ocial-contacts.version-2 li:before, .ocial-contacts.version-2 li:after, .ocial-contacts.version-2 .subtext {
    display: none;
  }
  .portfolio-gallery-list .portfolio-gallery-list-wrap {
    justify-content: center;
  }
  .portfolio-gallery-list .item {
    height: auto;
    min-height: 280px;
  }
  .portfolio-gallery-list .item .item-info .item-title {
    font-size: 26px;
    margin-bottom: 10px;
    max-height: 100%;
  }
  .portfolio-gallery-list .item .item-info .leader .leader-text .leader-text-title {
    font-size: 18px;
  }
  .current-event .texting-type-1 .texting-row .item.info .text a.button {
    margin: 0 auto;
  }
  .services-includes .services-includes-list {
    flex-direction: column;
    margin-top: 40px;
  }
  .services-includes .services-includes-list .item {
    width: 100%;
  }
  .information-about-event .right-side .slider-big {
    width: 100%;
    margin-bottom: 100px;
  }
  .information-about-event .right-side .slider-big .item {
    height: 350px;
    margin-right: 0;
  }
  .information-about-event .right-side .slider-navigation {
    display: none;
  }
  .ctext-img.three img {
    height: 220px;
  }
  .ctext-img.three img:nth-child(3) {
    margin-top: 10px;
  }
  .current-news .current-news-next {
    padding: 10px 15px;
  }
  .current-news .current-news-next .prev-news .news-img, .current-news .current-news-next .next-news .news-img {
    height: 30px;
    width: 30px;
  }
  .current-news .current-news-next .prev-news .news-text, .current-news .current-news-next .next-news .news-text {
    margin: 0 15px;
  }
  .comments-section .title {
    margin: 30px 0px;
  }
  .comments-section .comments-holder .comments-item {
    margin-bottom: 20px;
  }
  .comments-section .comments-holder .comments-item .comment-top {
    margin: 1em 0 1em;
    justify-content: space-between;
  }
  .comments-section .comments-holder .comments-item .comment-top .comment-date {
    text-align: right;
    padding-left: 10px;
  }
  .comments-section .comments-holder .comments-item .comment-top .comment-date:before {
    display: none;
  }
  .comments-section .comments-holder .comments-item .comments-item {
    margin-left: 10px;
  }
  .comments-section .comments-form .comments-form-tag form {
    flex-direction: column;
  }
  .comments-section .comments-form .comments-form-tag .form-comment {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
  }
  .comments-section .comments-form .comments-form-tag .form-other {
    width: 100%;
  }
  .comments-section .comments-form .comments-form-tag .form-other .input {
    max-width: 100%;
  }
  .comments-section .comments-form .comments-form-tag .button {
    margin: 0 auto;
  }
}

@media screen and (max-width: 479px) {
  .index-specialist .index-specialist-list .big-list {
    width: 300px;
    height: 370px;
    margin-bottom: 13px;
  }
  .index-specialist .index-specialist-list .big-list .specialist-info .specialist-info-text {
    font-size: 21px;
  }
  .index-specialist .index-specialist-list .list-rows .list-item {
    width: 135px;
    height: 155px;
  }
  .index-black-slider .slider-section .slider-big .item {
    height: 200px;
  }
  .index-happy-width-us-items {
    flex-direction: column;
  }
  .index-happy-width-us-items .item {
    width: 100%;
  }
  .feedback-popup.detail {
    max-width: 340px;
    padding: 15px;
  }
  .leading-person-info .leading-person-slider {
    max-width: 320px;
  }
  .leading-person-info .leading-person-slider .item {
    height: 210px;
    margin: 0 0px;
  }
  .leading-person-info .leading-person-youtube {
    height: 190px;
  }
  .services-includes .services-includes-list .item .item-header .item-title {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .services-includes .services-includes-list .item .item-header .item-title:after {
    bottom: -7px;
  }
  .information-about-event .right-side .slider-big .item {
    height: 300px;
  }
  .ctext-img.three img {
    height: 150px;
  }
}

@media screen and (max-width: 380px) {
  .information-about-event .right-side .slider-big .item {
    height: 230px;
  }
  .ctext-img.three img {
    height: 120px;
  }
}
